<template>
  <div
    class="shipping-day-info"
    :class="{ 'tab-uppercase': uppercase }"
  >
    <s-tab
      v-model="tabIndex"
      :show-line-when-single="false"
      no-border
      @change="tabChangeHandle"
    >
      <template v-for="(tab, index) in shippingMethods">
        <s-tab-item
          v-if="filterTab(tab)"
          :id="index"
          :key="index"
        >
          {{ tab.title && tab.title.toLocaleUpperCase() }}
        </s-tab-item>
      </template>
    </s-tab>
    <div class="shipping-day-percent">
      <div :class="{ 'shipping-day-percent__title': titlePadding }">
        <p
          v-if="desc"
          class="shipping-day-percent__desc"
          v-html="desc"
        ></p>
      </div>
      <div
        v-if="percentList.length"
        class="shipping-day-percent__list"
      >
        <div class="shipping-day-percent__list-days">
          <p 
            v-for="percentItem in percentList"
            :key="`${ percentItem.day }-${ percentItem.percent }`"
            class="shipping-day-percent__list-item"
          >
            {{ formatDayPercent({ type: 'day', data: percentItem }) }}
          </p>
        </div>
        <div class="shipping-day-percent__list-progress">
          <div 
            v-for="percentItem in percentList"
            :key="`${ percentItem.day }-${ percentItem.percent }`"
            class="shipping-day-percent__list-bar shipping-day-percent__list-item"
          >
            <i
              class="shipping-day-percent__list-bar-fill"
              :style="{ width: `${ percentItem.percent || 0 }%` }"
            ></i>
          </div>
        </div>
        <div class="shipping-day-percent__list-percent">
          <p
            v-for="percentItem in percentList"
            :key="`${ percentItem.day }-${ percentItem.percent }`"
            class="shipping-day-percent__list-item"
          >
            {{ formatDayPercent({ type: 'percent', data: percentItem }) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { isArray } from '@shein/common-function'
import { Tab, TabItem } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-40-1' })
;[Tab, TabItem].forEach(c => Vue.use(c))

export default {
  props: {
    shippingMethods: {
      type: Array,
      default() {
        return []
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    activeIndex: {
      type: [String, Number],
      default: ''
    },
    needFilter: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    titlePadding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    selectedShippingDayPercent() {
      if (!isArray(this.shippingMethods)) {
        return {}
      }
      return this.shippingMethods?.[this.tabIndex] || {}
    },
    desc() {
      const {
        desc_type = '',
        delivery_day_percent_desc = '',
        shipping_day_percent_desc = ''
      } = this.selectedShippingDayPercent || {}
      return Number(desc_type) === 1
        ? delivery_day_percent_desc
        : shipping_day_percent_desc
    },
    percentList() {
      const { day_percents = [] } = this.selectedShippingDayPercent || {}
      if (!isArray(day_percents)) {
        return []
      }
      
      return day_percents
    }
  },
  watch: {
    activeIndex: {
      immediate: true,
      handler(val) {
        if (val >= 0 && val !== '') {
          this.tabIndex = val
        }
      }
    },
    tabIndex: {
      immediate: true,
      handler(flag) {
        daEventCenter.triggerNotice({
          daId: '1-40-1-3',
          extraData: {
            shippingMethod: this.shippingMethods[flag]?.transport_type
          }
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getTrueIndex()
    })
  },
  methods: {
    getTrueIndex() {
      if (this.shippingMethods[this.tabIndex]?.day_percents?.length) return
      this.tabIndex = this.shippingMethods.findIndex(
        item => item?.day_percents?.length
      )
    },
    filterTab(tab) {
      const { needFilter } = this
      if (!needFilter) return true
      return !!tab?.day_percents?.length
    },
    formatDayPercent({ type = 'day', data = {} }) {
      const {
        SHEIN_KEY_PWA_23130 = '',
        SHEIN_KEY_PWA_23129 = '',
        SHEIN_KEY_PWA_23131 = ''
      } = this.language || {}
      const { day, percent } = data || {}
      if (type === 'day') {
        return (Number(day) === 1
          ? SHEIN_KEY_PWA_23129
          : SHEIN_KEY_PWA_23130
        ).replace('{0}', day)
      }
      return SHEIN_KEY_PWA_23131.replace('{0}', percent)
    },
    tabChangeHandle(val) {
      this.$emit('change', val)
    }
  }
}
</script>
<style lang="less" scoped>
.shipping-day-info {
  &.tab-uppercase {
    /deep/.S-tab-item__inner {
      text-transform: capitalize;
    }
  }
}
.shipping-day-percent {
  &__title {
    padding: 0 24/75rem;
  }
  &__desc {
    padding: 0.213rem;
    font-size: 14px;
    text-align: center;
    color: @sui_color_success;
    background: @sui_color_safety_bg;
  }
  &__list {
    display: flex;
    align-items: stretch;
    padding: 0 0.32rem;
  }

  &__list-item {
    margin-top: 8px;
  }

  &__list-days {
    white-space: nowrap;
  }

  &__list-percent {
    text-align: right;
    white-space: nowrap;
  }

  &__list-progress {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    margin: 0 16/75rem;
  }

  &__list-bar {
    position: relative;
    height: 0.16rem;
    border-radius: 0.106rem;
    background: @sui_color_gray_weak1;
    overflow: hidden;
  }

  &__list-bar-fill {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    background: @sui_color_gray_dark1;
  }
}
</style>
